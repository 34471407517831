import React from 'react'
import PageTitle from '../../common/PageTitle/PageTitle'
import PageImageServices from '../../common/PageImage/PageImageServices'
import { Helmet } from 'react-helmet'

const Manufacturing = () => {
	return (
		<div>
			<Helmet>
				<title>Manufacturing | Services | EGS</title>
				<meta name='description' content='Edge Global Services manufacturing.' />
				<meta name='keywords' content='' />
			</Helmet>
			<PageTitle h1='Factory & Assembly Plant Set up' />
			<div className='container'>
				<div className='row no-gutters'>
					<div className='col-lg-6 services-text' style={{alignItems: 'end'}}>
						<div>
							<span>01</span>
							<div>FACTORY SET UP IN LOW COST & DEVELOPING COUNTRIES</div>
						</div>
					</div>
					<div className='col-lg-6'>
						<PageImageServices
							pageImage={`${process.env.REACT_APP_SITE_CONTENT}services/f_img1.jpg`}
							altText='EGS - FACTORY SET UP IN LOW COST & DEVELOPING COUNTRIES'
						/>
					</div>
				</div>
				<div className='row no-gutters'>
					<div className='col-lg-6 order-2 order-lg-1'>
						<PageImageServices
							pageImage={`${process.env.REACT_APP_SITE_CONTENT}services/f_img2.jpg`}
							altText='EGS - PRODUCTION FACILITY ON-SHORING FROM CHINA'
						/>
					</div>
					<div className='col-lg-6 order-1 order-lg-2 services-text' style={{alignItems: 'start'}}>
						<div>
							<span>02</span>
							<div>PRODUCTION FACILITY ON-SHORING FROM CHINA</div>
						</div>
					</div>
				</div>
				<div className='row no-gutters'>
					<div className='col-lg-6 services-text' style={{alignItems: 'end'}}>
							<div>
								<span>03</span>
								<div>SUPPLY CHAIN TRANSFER AND CRITICAL COMPONENT SOURCING</div>
							</div>
					</div>
					<div className='col-lg-6'>
						<PageImageServices
							pageImage={`${process.env.REACT_APP_SITE_CONTENT}services/f_img3.jpg`}
							altText='EGS - SUPPLY CHAIN TRANSFER AND CRITICAL COMPONENT SOURCING'
						/>
					</div>
				</div>
				<div className='row no-gutters'>
					<div className='col-lg-6 order-2 order-lg-1'>
						<PageImageServices
							pageImage={`${process.env.REACT_APP_SITE_CONTENT}services/f_img4.jpg`}
							altText='EGS - PRODUCTIONISATION OF PRODUCTS'
						/>
					</div>
					<div className='col-lg-6 order-1 order-lg-2 services-text' style={{alignItems: 'start'}}>
							<div>
								<span>04</span>
								<div>PRODUCTIONISATION OF PRODUCTS</div>
							</div>
						</div>
				</div>
			</div>
		</div>
	)
}

export default Manufacturing
