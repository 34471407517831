import React, { useState } from 'react'
import axios from 'axios'

import { toast } from 'react-toastify'

const ContactForm = () => {
  const initialFormState = {
    name: '',
    email: '',
    phone: '',
    company: '',
    message: '',
  }

  const [form, setForm] = useState(initialFormState)
  const [loading, setLoading] = useState(false)

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    axios
      .post(`${process.env.REACT_APP_AZURE_FUNCTION_URL}`, form)
      .then(function (r) {
        // setForm({...initialFormState})
        toast.success(`Thank you for your interest! We'll be in contact.`)
      })
      .catch(function (r) {
        toast.error('Please try again later or give us a call. Thank you')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleFormChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  return (
    <div className="contact-form">
      <form id="contact-form" onSubmit={handleFormSubmit}>
        <div className="row clearfix">
          <div className="col-md-6 col-sm-6 col-xs-12 form-group">
            <input type="text" name="name" placeholder="Name" required onChange={handleFormChange} disabled={loading} />
          </div>

          <div className="col-md-6 col-sm-6 col-xs-12 form-group">
            <input
              type="email"
              name="email"
              placeholder="E-Mail"
              required
              onChange={handleFormChange}
              disabled={loading}
            />
          </div>

          <div className="col-md-6 col-sm-6 col-xs-12 form-group">
            <input
              type="text"
              name="phone"
              placeholder="Phone"
              required
              onChange={handleFormChange}
              disabled={loading}
            />
          </div>

          <div className="col-md-6 col-sm-6 col-xs-12 form-group">
            <input
              type="text"
              name="company"
              placeholder="Company"
              required
              onChange={handleFormChange}
              disabled={loading}
            />
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
            <textarea name="message" placeholder="Message" onChange={handleFormChange} disabled={loading}></textarea>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
            <button className="theme-btn submit-btn" type="submit" name="submit-form" disabled={loading}>
              {!loading ? (
                <span>
                  Submit Now <i className="fas fa-arrow-right"></i>
                </span>
              ) : (
                <span>Please wait...</span>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
