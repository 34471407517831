import React from 'react'
import { Link } from 'react-router-dom'

// import manufacture1 from '../../assets/home-services/manufacture1.png'
// import supply from '../../assets/home-services/supply.png'
// import strategy from '../../assets/home-services/strategy.png'
// import sourcing from '../../assets/home-services/sourcing.png'

const OurServices = () => {
  const data = [
    {
      url: '/services/manufacturing',
      urlName: 'Manufacturing',
      image: `${process.env.REACT_APP_SITE_CONTENT}manufacture1.png`,
      imageAlt: 'EGS - Manufacture Services',
      text: 'Factory sourcing, quality control & production performance enhancement',
    },
    {
      url: '/services/procurement',
      urlName: 'Procurement & Supply Chain',
      image: `${process.env.REACT_APP_SITE_CONTENT}supply.png`,
      imageAlt: 'EGS - Procurement & Supply Chain Services',
      text: 'Cost avoidance, category management & supply chain strategy',
    },
    {
      url: '/services/product-strategy',
      urlName: 'Product Strategy',
      image:`${process.env.REACT_APP_SITE_CONTENT}strategy.png`,
      imageAlt: 'EGS - Product Strategy Services',
      text: 'Market landscaping, brand positioning & product design',
    },
    {
      url: '/services/manufacturing',
      urlName: 'Sourcing',
      image: `${process.env.REACT_APP_SITE_CONTENT}sourcing.png`,
      imageAlt: 'EGS - Sourcing Services',
      text: 'Value engineering, parts fabrication and consumer goods consultancy',
    },
  ]

  return (
    <div className="row">
      <div className="col-lg-12 text-center pt-5 pb-4">
        <div className="sec-title text-center">
          <h2>Our Services</h2>
          <div>
            <span className="separator"></span>
          </div>
        </div>
      </div>
      {data.map((d, i) => (
        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-2" key={i}>
          <div className="market-block-four text-center">
            <div className="lower-content">
              <h4 style={{height: 50}}>
                <Link to={d.url} style={{ color: '#262261' }}>
                  {d.urlName}
                </Link>
              </h4>
              <div className="icon-box" style={{ marginTop: 45 }}>
                <img src={d.image} className="img-fluid" alt={d.imageAlt} />
              </div>
              <br />
              <p>{d.text}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default OurServices
