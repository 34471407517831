import React from 'react'

const RequestQuote = () => (
  <div className="container">
    <div className="row ">
      <div className="col-lg-7">
        <div className="card quote-box my-5">
          <div className="card-body">
            <div>
              <h2>REQUEST FOR QUOTE</h2>
              <div className="separator"></div>
            </div>
            <form className="my-5">
              <div className="form-group row">
                <div className="col-sm-12 pb-2">
                  <select className="form-control-egs">
                    <option value="">Select Needed Services</option>
                    <option value="1">Service One</option>
                    <option value="2">Service Two</option>
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-6 pb-4">
                  <input type="tel" name="" id="" className="form-control-egs" placeholder="Phone" />
                </div>
                <div className="col-sm-6 pb-4">
                  <input type="email" name="" id="" className="form-control-egs" placeholder="Email Address" />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-12">
                  <button className="btn btn-primary px-5 py-3">Send Now <i className="fas fa-arrow-right"></i></button>
                  <div className="text" style={{fontSize: 18, position: 'absolute',top: 0, left: 220, color: '#c4c4c4'}}>Get a free quote for your business, We are here 24/7.</div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default RequestQuote
