import React from 'react'

const SocialMediaLinks = () => (
  <>
    <a href="https://www.facebook.com/edgeglobalservices/" className="text-white">
      <i className="fab fa-facebook-f m-3"></i>
    </a>
    {/* <Link to="/" className="text-white">
                    <i className="fab fa-twitter m-3"></i>
                  </Link> */}
    <a href="https://www.linkedin.com/company/edge-global-services-ltd/?viewAsMember=true" className="text-white">
      <i className="fab fa-linkedin-in m-3"></i>
    </a>
  </>
)

export default SocialMediaLinks
