import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import './App.css';
import Header from './common/Header/Header';
import Footer from './common/Footer/Footer';
import 'react-toastify/dist/ReactToastify.css';

import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/dist/jquery.min'
import 'popper.js/dist/popper.min'
import 'bootstrap/dist/js/bootstrap.min'

import Home from './components/Home/Home';
import AboutUs from './components/About/AboutUs';
import Contact from './components/Contact/Contact';
import News from './components/News/News';
import Industries from './components/Industries/Industries';
import WeOperate from './components/WeOperate/WeOperate';
import Manufacturing from './components/Services/Manufacturing';
import Procurement from './components/Services/Procurement';
import ProductStrategy from './components/Services/ProductStrategy';
import { ToastContainer } from 'react-toastify';
import Brands from './components/Brands/Brands';
import NotFound from './components/NotFound/NotFound';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';

import ReactGA from 'react-ga';
import IpCreation from './components/Services/IpCreation';

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-5FYH9D08Q6');
    ReactGA.pageview(location.pathname);
  }, [location])

  return (
    <>
      <Header />
      <div style={{ paddingTop: 200 }}>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/brands" component={Brands} />
          <Route path="/services/manufacturing" component={Manufacturing} />
          <Route path="/services/procurement" component={Procurement} />
          <Route path="/services/product-strategy" component={ProductStrategy} />
          <Route path="/services/ip-creation" component={IpCreation} />
          <Route path="/industries" component={Industries} />
          <Route path="/we-operate" component={WeOperate} />
          <Route path="/news" component={News} />
          <Route path="/contact" component={Contact} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route component={NotFound} />
        </Switch>
      </div>

      <Footer />
      <ToastContainer />
    </>
  );
}

export default App;
