import React from 'react'
import { Link, NavLink } from 'react-router-dom'

import './Header.css'
import NavBar from './NavBar'
import SocialMediaLinks from '../SocialMediaLinks/SocialMediaLinks'

const Header = () => {
  const links = [
    { name: 'Home', url: '/' },
    { name: 'About Us', url: '/about-us' },
    { name: 'Brands', url: '/brands' },
    {
      name: 'Services',
      url: '#',
      children: [
        { name: 'Manufacturing', url: '/services/manufacturing' },
        { name: 'Procurement & Supply Chain', url: '/services/procurement' },
        { name: 'Product Strategy', url: '/services/product-strategy' },
        { name: 'IP Creation', url: '/services/ip-creation' },
      ],
    },
    { name: 'Industries', url: '/industries' },
    { name: 'We Operate', url: '/we-operate' },
    { name: 'News', url: '/news' },
    { name: 'Contact', url: '/contact' },
  ].map((linkName) => (
    <React.Fragment key={linkName.name}>
      {linkName.children === undefined ? (
        <li className="nav-item">
          <NavLink className="nav-link" to={linkName.url} style={{ color: '#262261' }} activeClassName="current" exact>
            {linkName.name}
          </NavLink>
        </li>
      ) : (
        <li className="nav-item dropdown">
          <NavLink
            style={{ color: '#262261' }}
            className="nav-link dropdown-toggle"
            to={linkName.url}
            id="dropdown01"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {linkName.name}
          </NavLink>
          <div className="dropdown-menu" aria-labelledby="dropdown01">
            {linkName.children.map((child) => (
              <NavLink className="dropdown-item" to={child.url} key={child.name}>
                {child.name}
              </NavLink>
            ))}
          </div>
        </li>
      )}
    </React.Fragment>
  ))

  return (
    <div style={{ position: 'absolute', width: '100%', zIndex: 10 }}>
      <header className="bg-edge-blue">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 order-lg-12 text-light">
              <div className="row">
                <div className="col-lg-5 col-sm-6 above-navbar d-none d-sm-inline-block">
                  <div className="ml-3">
                    Need Help? Talk to an Expert
                    <br />
                    <span className="text-edge-light-blue">+44 (0) 1172980690</span>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 above-navbar d-none d-sm-inline-block">
                  <div className="ml-3">
                    Email us at
                    <br />
                    <span className="text-edge-light-blue">
                      <a href="mailto:info@egs.direct">info@egs-global.com</a>
                    </span>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 above-navbar">
                  <div className="text-center">
                    <SocialMediaLinks />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 order-lg-1 text-center bg-white">
              <Link to="/">
                <img
                  src={`${process.env.REACT_APP_SITE_CONTENT}logo.jpg`}
                  alt="Edge Global Services"
                  style={{ zIndex: 10, position: 'relative' }}
                />
              </Link>
            </div>
          </div>
        </div>
        <NavBar links={links} />
      </header>
    </div>
  )
}

export default Header
