import React from 'react'

const ContactInfo = () => {
  return (
    <div className="upper-box">
      <ul className="list-style-three">
        <li>
          <i className="fas fa-phone-alt icon"></i>
          <strong>Call Us:</strong>
          <br />
          Land line +44 (0) 1172980690
        </li>
        <li>
          <i className="fas fa-envelope icon"></i>
          <strong>Mail Us:</strong>
          <br />
          info@egs-global.com
        </li>
        <li>
          <i className="fas fa-clock icon"></i>
          <strong>Office Hrs:</strong>
          <br />
          Monday - Saturday: 9.00am to 5.00pm
        </li>
      </ul>
    </div>
  )
}

export default ContactInfo
