import React from 'react'
import PageTitle from '../../common/PageTitle/PageTitle'
import ContactForm from './ContactForm'
import ContactInfo from './ContactInfo'
import { Helmet } from 'react-helmet'

const Contact = () => {
  document.title = 'Contact - EGS'
  return (
    <>
      <Helmet>
        <title>Contact | EGS</title>
        <meta
          name="description"
          content="Experts in manufacturing, product development and out of the box solutions to drive your business forward in the areas that cost your business resource and capital."
        />
        <meta name="keywords" content="" />
      </Helmet>
      <PageTitle
        h1="Contact Us"
        h4="Experts in manufacturing, product development and out of the box solutions to drive your business forward in the areas that cost your business resource and capital."
      />
      <div className="container my-5">
        <div className="row">
          <div className="col-xl-3 sec-title">
            <h2>Quick Contact</h2>
            <div className="separator"></div>
          </div>
          <div className="col-xl-9">
            <p className="text">
              You have questions? Dont hesitate to contact us, Our support team here to
              <br />
              help you by 24*7.
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-xl-4">
            <ContactInfo />
          </div>
          <div className="col-xl-8">
            <ContactForm />
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact
