import React from 'react'

import PageTitle from '../../common/PageTitle/PageTitle'
import IndustryCard from './IndustryCard'
import { Helmet } from 'react-helmet'

const Industries = () => {
  const data = [
    {
      imageUrl: `${process.env.REACT_APP_SITE_CONTENT}defence.jpg`,
      link: '',
      linkDescription: 'Defence & Aerospace',
      header: 'Military & defence sourcing solutions',
      bulletPoints: [
        'Body Armour to NIJ level 4 and VIP concealed options',
        'UAV tactical solutions, drone blocker and tracking',
        'Mobile encryption',
      ],
    },
    {
      imageUrl: `${process.env.REACT_APP_SITE_CONTENT}technology.jpg`,
      link: '',
      linkDescription: 'Technology',
      header: 'Technology souring and development',
      bulletPoints: [
        'Mobile computing design',
        'Hardware sourcing',
        'Industrial data compression solutions',
        'Battery technology',
      ],
    },
    {
      imageUrl: `${process.env.REACT_APP_SITE_CONTENT}manufacturing.jpg`,
      link: '',
      linkDescription: 'Manufacturing',
      header: 'Consumer products',
      bulletPoints: [
        'DIY and Power tools',
        'Industrial products',
        'Consumer electronics',
        'All consumer goods',
        'Tooling and product parts',
      ],
    },
    {
      imageUrl: `${process.env.REACT_APP_SITE_CONTENT}FMCG.jpg`,
      link: '',
      linkDescription: 'FMCG',
      header: 'Distribution & Import / export',
      bulletPoints: ['Low cost economy solutions', 'Quality Control', 'Cost Avoidance'],
    },
  ]
  return (
    <>
      <Helmet>
        <title>Industries | EGS</title>
        <meta
          name="description"
          content="Edge Global Services supporting the core Industries that drive the world forward."
        />
        <meta name="keywords" content="" />
      </Helmet>
      <PageTitle
        h1="Industries"
        h4="Edge Global Services supporting the core Industries that drive the world forward."
      />
      <div className="container">
        <div className="row my-5">
          {data.map((card) => (
            <div className="col-xl-3" key={card.header}>
              <IndustryCard
                imageUrl={card.imageUrl}
                link={card.link}
                linkDescription={card.linkDescription}
                header={card.header}
                bulletPoints={card.bulletPoints}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Industries
