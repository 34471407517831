import React from 'react'

const PageImage = ({ pageImage, altText }) => (
  <div className="container">
    <div className="row">
      <div className="col-xl-12 py-4">
        <img src={pageImage} alt={altText} className="img-fluid" />
      </div>
    </div>
  </div>
)

export default PageImage
