import React from 'react'
import PageTitle from '../../common/PageTitle/PageTitle'
import { Helmet } from 'react-helmet'
import PageImageServices from '../../common/PageImage/PageImageServices'

const Procurement = () => {
	return (
		<>
			<Helmet>
				<title>Procurement & Supply Chain | Services | EGS</title>
				<meta name='description' content='Edge Global Services Procurement & Supply Chain.' />
				<meta name='keywords' content='' />
			</Helmet>
			<PageTitle h1='Procurement &amp; Supply Chain' />
			<div className='container'>
				<div className='row no-gutters'>
					<div className='col-lg-6 services-text' style={{ alignItems: 'end' }}>
						<div>
							<span>01</span>
							<div>COST AVOIDANCE</div>
              <small>
								<p>Our years of EXPERIENCE IN SUPPLY CHAIN MANAGEMENT HELP US</p>
                <p>Prepare your business FOR SUSTAINABLE GROWTH and maximise your capability</p>
                <p>TO DRIVE VALUE IN COMPETITIVE ENVIRONMENTS</p>
							</small>
						</div>
					</div>
					<div className='col-lg-6'>
						<PageImageServices
							pageImage={`${process.env.REACT_APP_SITE_CONTENT}services/p_img1.jpg`}
							altText='EGS - COST AVOIDANCE'
						/>
					</div>
				</div>
				<div className='row no-gutters'>
					<div className='col-lg-6 order-2 order-lg-1'>
						<PageImageServices
							pageImage={`${process.env.REACT_APP_SITE_CONTENT}services/p_img2.jpg`}
							altText='EGS - VALUE ENGINEERING'
						/>
					</div>
					<div className='col-lg-6 order-1 order-lg-2 services-text' style={{ alignItems: 'start' }}>
						<div>
							<span>02</span>
							<div>VALUE ENGINEERING</div>
              <small>
								<p>Using our GLOBAL MANUFACTURING NETWORK</p>
                <p>We work with you to finalise a PRODUCT DESIGN OPTIMISED FOR FAST DELIVERY</p>
                <p>THE HIGHEST STANDARDS OF PRODUCT QUALITY</p>
							</small>
						</div>
					</div>
				</div>
				<div className='row no-gutters'>
					<div className='col-lg-6 services-text' style={{ alignItems: 'end' }}>
						<div>
							<span>03</span>
							<div>PRODUCT RELOCATION</div>
							<small>
								<p>We combine our GLOBAL MARKET KNOWLEDGE WITH THE NEWEST TECHNOLOGIES</p>
                <p>To Create ACCURATE FORECASTING TOOLS FOR YOUR PRODUCT</p>
							</small>
						</div>
					</div>
					<div className='col-lg-6'>
						<PageImageServices
							pageImage={`${process.env.REACT_APP_SITE_CONTENT}services/p_img3.jpg`}
							altText='EGS - PRODUCT RELOCATION'
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default Procurement
