import React from 'react'
import PageTitle from '../../common/PageTitle/PageTitle'
import PageImage from '../../common/PageImage/PageImage'
import { Helmet } from 'react-helmet'

const WeOperate = () => {
  return (
    <>
      <Helmet>
        <title>Operate | EGS</title>
        <meta name="description" content="Edge Global Services area of operations." />
        <meta name="keywords" content="" />
      </Helmet>
      <PageTitle h1="We Operate" h4="" />
      <PageImage pageImage={`${process.env.REACT_APP_SITE_CONTENT}locations.png`} altText="EGS - We Operate" />
    </>
  )
}

export default WeOperate
