import React from 'react'
import PageTitle from '../../common/PageTitle/PageTitle'
import { Helmet } from 'react-helmet'
import './PrivacyPolicy.css'

const PrivacyPolicy = () => (
  <>
    <Helmet>
      <title>Privacy Policy | EGS</title>
    </Helmet>
    <PageTitle h1="Privacy Policy" />
    <div className="container">
      <div className="row">
        <div className="col pt-2">
          <p>Edge Global Services are committed to protecting and respecting your privacy.</p>
          <p>
            This policy sets out the basis on which any personal data We collect from you or that you provide Us, will
            be processed by Us.
          </p>
          <p>
            We may gather and process your personal information in accordance with this policy and in compliance with
            the relevant data protection regulation and laws. This policy provides you with the necessary information
            regarding your rights and our obligations, and explains how, why and when We process your personal data.
          </p>
          <p>
            For the purpose of the General Data Protection Regulations (GDPR), the data controller is Edge Global
            Services Ltd whose registered office is at 3rd Floor, Old Stock Exchange,St Nicholas Street, BS1 1TG, UK and
            We are a company registered in England and Wales
          </p>
          <h4>INFORMATION THAT WE MAY COLLECT</h4>
          <strong>We may collect and process the following data about you:</strong>
          <ul className="privacy">
            <li>Name</li>
            <li>Personal Email</li>
            <li>Business Email</li>
            <li>Home Telephone Number</li>
            <li>Mobile Telephone Number</li>
            <li>Business Address</li>
            <li>Information required for provision of our services to you.</li>
            <li>Information provided by letter, email, facsimile and telephone.</li>
            <li>If you contact us We may keep a record of that correspondence.</li>
            <li>
              Details of your visits to our websites including, but not limited to, traffic data location data, weblogs,
              and other communication data whether this is required for invoicing purposes or otherwise and the
              resources you access.
            </li>
          </ul>
          <strong>How We Use Your Personal Data (Legal Basis for Processing)</strong>
          <p>
            We take your privacy very seriously and will never disclose, share or sell your data without your consent;
            unless required to do so by law. We only retain your data for as long as is necessary and for the purpose(s)
            specified in this policy. Where you have consented to us providing you with promotional offers and
            marketing, you are free to withdraw this consent at any time.
          </p>
          <strong>We use information held about you in the following ways:</strong>
          <ul className="privacy">
            <li>To carry out our obligations arising from any contracts entered into between you and Us.</li>
            <li>
              To provide you with information, products or services that you request from Us or which We feel may
              interest you, where you have consented to be contacted for such purposes.
            </li>
            <li>To notify you about changes to our services</li>
          </ul>
          <strong>Your Rights</strong>
          <p>
            We will ask for your authority if We wish to use your personal data for marketing purposes or if We intend
            to disclose your information to any third party for such purposes . We will not use your personal data in
            this way unless you have given authority.
          </p>
          <p>
            The Act gives you the right to access information held about you free of charge. Contact us at our address
            detailed below if you wish to exercise your rights.
          </p>
          <p>
            If We receive a request from you to exercise any of the above rights, We may ask you to verify your identity
            before acting on the request; this is to ensure that your data is protected and kept secure.
          </p>
          <strong>Sharing and Disclosing Your Personal Information</strong>
          <p>
            We do not share or disclose any of your personal information without your consent, other than for the
            provision of our services as specified in this policy or where there is a legal requirement.
          </p>
          <strong>Safeguarding Measures</strong>
          <p>
            We take your privacy seriously and will take all steps reasonably necessary to ensure you’re your personal
            data is treated securely and in accordance with this policy. All information you provide to Us is stored on
            secure servers.
          </p>
          <p>
            Unfortunately, the transmission of information via the internet is not completely secure. Although We will
            do our best to protect your personal data, We cannot guarantee the security of your data transmitted to our
            site, any transmission is at your own risk.
          </p>
          <strong>How Long We Keep Your Data</strong>
          <p>
            We only retain personal information for as long as is necessary. However, We will retain your records for as
            long as you are a client unless you advise otherwise.
          </p>
          <p>
            Where you have consented to us using your details for direct marketing, We will keep such data until you
            notify Us otherwise and/or withdraw your consent.
          </p>
          <strong>Changes to Privacy Policy</strong>
          <p>Any changes We make to our privacy policy in the future will be reflected on our website.</p>
          <strong>How to Contact Us</strong>
          <p>
            If you require any further information about this policy or have a complaint regarding the processing of
            your personal data or are unsatisfied with how We have handled your information, please email{' '}
            <a href="mailto:info@egs-global.com">info@egs-global.com</a> or write to:
          </p>
          <p>The Data Protection Officer</p>
          <p>
            <strong>Edge Global Services Ltd</strong>
            <br />
            3rd Floor <br />
            Old Stock Exchange <br />
            St Nicholas Street <br />
            BS1 1TG <br />
            UK
          </p>
          <p>
            <strong>Phone.</strong> +44 (0) 1172980690
          </p>
        </div>
      </div>
    </div>
  </>
)

export default PrivacyPolicy
