import React from 'react'
import PageTitle from '../../common/PageTitle/PageTitle'
import { Helmet } from 'react-helmet'

const News = () => {
  return (
    <>
      <Helmet>
        <title>News | EGS</title>
        <meta name="description" content="Edge Global Services lastest news." />
        <meta name="keywords" content="" />
      </Helmet>
      <PageTitle h1="News" h4="" />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 my-5">
            <div className="card">
              <video controls className="card-img-top" name="media">
                <source src={`${process.env.REACT_APP_SITE_CONTENT}myair.MOV`} type="video/mp4" />
              </video>
              <div className="card-body">
                <h5 className="card-title">MyAir</h5>
                {/* <p className="text">

                </p>
                <a href="https://lnkd.in/eVafzTs" className="read-more" target="_blank" rel="noopener noreferrer">
                  CLICK HERE FOR FULL INTERVIEW <i className="fas fa-arrow-right"></i>
                </a> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6 my-5">
            <div className="card">
              <video controls className="card-img-top" name="media">
                <source src={`${process.env.REACT_APP_SITE_CONTENT}spacegym.MOV`} type="video/mp4" />
              </video>
              <div className="card-body">
                <h5 className="card-title">SpaceGym</h5>
                <p className="text">

                </p>
                <a href="https://www.qvcuk.com/JML-Space-Gym-Flywheel-Fitness-Trainer.product.737109.html?sc=SRCH" className="read-more" target="_blank" rel="noopener noreferrer">
                JML Space Gym Flywheel Fitness Trainer - QVC UK <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 my-5">
            <div className="card">
              <video controls className="card-img-top" name="media">
                <source src={`${process.env.REACT_APP_SITE_CONTENT}egs-lou.mp4`} type="video/mp4" />
              </video>
              <div className="card-body">
                <h5 className="card-title">Developing &amp; manufacturing new product in China</h5>
                <p className="text">
                  How to develop new products in China. A small segment with Lou Street founder of EGS , discussIng all
                  aspects of developing new products with Chinese factories, how to save time, money and de-risk the
                  process. We cover developing a specification, finding the partner factory, prototypes, costs for
                  tooling, first build, QC. This is a crash course in getting the best out of China.
                </p>
                <a href="https://lnkd.in/eVafzTs" className="read-more" target="_blank" rel="noopener noreferrer">
                  CLICK HERE FOR FULL INTERVIEW <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default News
