import React from 'react'
import PageTitle from '../../common/PageTitle/PageTitle'
import { Helmet } from 'react-helmet'

const Brands = () => {
  const data = [
        {
      brandName: 'SpaceGym',
      text: `Unique home fitness solutions using flywheel technology fully patented design and function.`,
      url: 'https://www.spacegym.co.uk/',
      brandLogoUrl: `${process.env.REACT_APP_SITE_CONTENT}spacegym.jpg`,
      altText: ''
    },
    {
      brandName: 'Gelpole',
      text: `Gelpole designed to meet the needs of today’s sanitizing requirements for office’s, out door spaces, schools, hotels, public transport and more.`,
      url: 'https://www.gelpole.com',
      brandLogoUrl: `${process.env.REACT_APP_SITE_CONTENT}EGS-Gelpole.png`,
      altText: ''
    },
    {
      brandName: 'Airgill',
      text: `Clear full protection mask, designed for prolonged use in urban and high traffic areas, will be certified to N99 standards with low cost filters that last up to 3 months.`,
      url: 'https://airgill.co.uk/',
      brandLogoUrl: `${process.env.REACT_APP_SITE_CONTENT}myair.PNG`,
      // brandLogoUrl: `${process.env.REACT_APP_SITE_CONTENT}EGS-airgrill.jpg`,
      altText: ''
    },
    {
      brandName: 'EDGE',
      text: `This is Edge Global Services own brand for consumer goods, we currently have a range in development across white / brown goods and professional power tools.`,
      url: '',
      brandLogoUrl: `${process.env.REACT_APP_SITE_CONTENT}EGS-EDGE.png`,
      altText: ''
    },

  ]
  return (
    <>
      <Helmet>
        <title>Brands | EGS</title>
        <meta name="description" content="Edge Global Service Brands" />
        <meta name="keywords" content="" />
      </Helmet>
      <PageTitle h1="Brands" h4="Edge Global Service Brands" />
      <div className="container">
        {data.map((d, i) => (
          <div className="row pt-5" key={i}>
            <div className="col-lg-3 mb-3 text-center">
              <img src={d.brandLogoUrl} alt={d.altText} className="img-fluid mt-3"/>
            </div>
            <div className="col-lg-9 mb-3">
              <div className="">
                <div className="col-xl-3 sec-title">
                  <h2>{d.brandName}</h2>
                  <div className="separator"></div>
                </div>
                <div className="card-body">
                  <p className="text">{d.text}</p>
                  <p>
                    {d.url ? (
                      <a href={d.url} target="_blank" rel="noopener noreferrer" className="read-more">
                        Go to the {d.brandName} website  <i className="fas fa-arrow-right"></i>
                      </a>
                    ) : null}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default Brands
