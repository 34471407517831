import React from 'react'
import { Link } from 'react-router-dom'

import './Footer.css'

import SocialMediaLinks from '../SocialMediaLinks/SocialMediaLinks'

const Footer = () => {
	return (
		<div className='bg-edge-blue py-5 text-white'>
			<div className='container'>
				<div className='row'>
					<div className='col-md-4'>
						<img src={`${process.env.REACT_APP_SITE_CONTENT}footer-logo.png`} alt='Edge Global Services Logo' />
						<p className='mt-4'>
							Edge global services is primarily a delivery consultancy service for businesses that need to succeed in
							key areas , procurement , cost avoidance, manufacturing , brand positioning .
						</p>
						<p>
							<SocialMediaLinks />
						</p>
					</div>
					{/* <div className="col-md-1"></div> */}
					<div className='col-md-8'>
						<div className='row'>
							<div className='col-lg-5 col-md-12'>
								<div className='footer-title'>
									<h2>Useful Links</h2>
								</div>
								<ul className='links'>
									<li>
										<i className='fas fa-check'></i>
										<Link to='/about-us'>About Us</Link>
									</li>
									<li>
										<i className='fas fa-check'></i>
										<Link to='/about-us'>Locations</Link>
									</li>
									<li>
										<i className='fas fa-check'></i>
										<Link to='/about-us'>Get a Quote</Link>
									</li>
									<li>
										<i className='fas fa-check'></i>
										<Link to='/about-us'>Contact Us</Link>
									</li>
									<li>
										<i className='fas fa-check'></i>
										<Link to='/privacy-policy'>Privacy Policy</Link>
									</li>
								</ul>
							</div>
							<div className='col-lg-7 col-md-12'>
								<div className='footer-title'>
									<h2>Get In Touch</h2>
								</div>
								<p className='para-text'>
									Edge Global Services Ltd, 470 Bath Road, Bristol, BS4 3AP
                  
                   {/* 3rd Floor Old Stock Exchange, Bristol, BS1 1TG */}
								</p>
								<p className='para-text-grey'>
									+44 (0) 1172980690 |{' '}
									<a href='mailto:info@egs-global.com' className='para-text-grey'>
										info@egs-global.com
									</a>
								</p>
								<div>
									<p className='bold'>Visit Our Office:</p>
									<p>Monday - Saturday: 9.00am to 5.00pm</p>
								</div>
								<p className='mt-4 para-text'>
									Edge Global Services is incorporated in the UK.
									<br />
									Registration number 10046101
									{/* <br /> VAT Reg 280 4337 12 */}
								</p>
								<p className='mt-4 bold'>China Office Address:</p>
								<p className='para-text'>
									Building 58, No.2618, New Songjiang Road, Songjiang District, Shanghai, China.
								</p>
							</div>
						</div>
					</div>
					<div className='col-sm-12 text-center pt-5'>
						Copyright © 2018 <span className='text-edge-light-blue'>Edge Global Services</span> All rights reserved.
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer
