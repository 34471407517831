import React from 'react'
import PageTitle from '../../common/PageTitle/PageTitle'
import PageImageServices from '../../common/PageImage/PageImageServices'
import { Helmet } from 'react-helmet'

const IpCreation = () => {
	return (
		<div>
			<Helmet>
				<title>IP Creation | Services | EGS</title>
				<meta name='description' content='Edge Global Services IP Creation.' />
				<meta name='keywords' content='' />
			</Helmet>
			<PageTitle h1='Industrial Design and IP Creation' />
			<div className='container'>
				<div className='row no-gutters'>
					<div className='col-lg-6 services-text' style={{alignItems: 'end'}}>
						<div>
							<span>01</span>
							<div>CREATION OF NEW PRODUCTS & COMPONENTS</div>
						</div>
					</div>
					<div className='col-lg-6'>
						<PageImageServices
							pageImage={`${process.env.REACT_APP_SITE_CONTENT}services/id_img1.jpg`}
							altText='EGS - CREATION OF NEW PRODUCTS & COMPONENTS'
						/>
					</div>
				</div>
				<div className='row no-gutters'>
					<div className='col-lg-6 order-2 order-lg-1'>
						<PageImageServices
							pageImage={`${process.env.REACT_APP_SITE_CONTENT}services/id_img2.jpg`}
							altText='EGS - PATENT BREAKING AND CREATION OF NEW PROTECTABLE INTELLECTUAL PROPERTY'
						/>
					</div>
					<div className='col-lg-6 order-1 order-lg-2 services-text' style={{alignItems: 'start'}}>
						<div>
							<span>02</span>
							<div>PATENT BREAKING AND CREATION OF NEW PROTECTABLE INTELLECTUAL PROPERTY</div>
						</div>
					</div>
				</div>
				<div className='row no-gutters'>
					<div className='col-lg-6 services-text' style={{alignItems: 'end'}}>
							<div>
								<span>03</span>
								<div>COMPLIANCE</div>
							</div>
					</div>
					<div className='col-lg-6'>
						<PageImageServices
							pageImage={`${process.env.REACT_APP_SITE_CONTENT}services/id_img3.jpg`}
							altText='EGS - COMPLIANCE'
						/>
					</div>
				</div>
				<div className='row no-gutters'>
					<div className='col-lg-6 order-2 order-lg-1'>
						<PageImageServices
							pageImage={`${process.env.REACT_APP_SITE_CONTENT}services/id_img4.jpg`}
							altText='EGS - QUALITY IMPROVEMENTS'
						/>
					</div>
					<div className='col-lg-6 order-1 order-lg-2 services-text' style={{alignItems: 'start'}}>
							<div>
								<span>04</span>
								<div>QUALITY IMPROVEMENTS</div>
							</div>
						</div>
				</div>
			</div>
		</div>
	)
}

export default IpCreation
