import React from 'react'
import { Link } from 'react-router-dom'

const Appointment = () => (
  <section className="quality-service">
    <div className="container">
      <div className="row clearfix">
        <div className="col-md-9 col-sm-12 col-xs-12">
          <h2>Quality service for industrial and manufacturing products.</h2>
        </div>
        <div className="link-column col-md-3 col-sm-12 col-xs-12">
          <Link className="apointment" to="/contact" style={{textDecoration: 'underline', color: 'white'}}>
            <strong>MAKE AN APPOINTMENT</strong> <i className="fas fa-arrow-right"></i>
          </Link>
        </div>
      </div>
    </div>
  </section>
)

export default Appointment
