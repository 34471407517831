import React from 'react'
import './PageTitle.css'

const PageTitle = ({ h1, h4 }) => (
  <div
    className="text-center page-title"
    style={{ backgroundImage: `url(${process.env.REACT_APP_SITE_CONTENT}6.jpg)` }}
  >
    <div className="container">
      <h1>{h1}</h1>
      <h4 className="text-white">{h4}</h4>
    </div>
  </div>
)

export default PageTitle
