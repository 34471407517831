import React from 'react'
import { Link } from 'react-router-dom'

const LifeAtEGS = () => (
  <div className="container py-5">
    <div className="row">
      <div className="col-lg-6">
        <video controls className="card-img-top" name="media" poster={`${process.env.REACT_APP_SITE_CONTENT}video-img.jpg`}>
          <source src={`${process.env.REACT_APP_SITE_CONTENT}video.mp4`} type="video/mp4" />
        </video>
      </div>
      <div className="col-lg-6">
        <h2>
          LIFE AT
          <br />
          EDGE GLOBAL SERVICES.
        </h2>
        <div className="separator centered"></div>
        <div className="text mt-4">
          With over 20 years of expertise across our specialist fields, EGS help your business gain the edge in today’s
          competitive and complex global markets.
        </div>
        <ul className="list-style-one">
          <li>
            <i className="fas fa-check-circle"></i>
            <strong>Our industry experts: </strong>Combining a wealth of industry knowledge with established, global
            connections for solutions that secure your business safe and sustainable business growth.
          </li>
          <li>
            <i className="fas fa-check-circle"></i>
            <strong>Our hands on, end-to-end services:</strong>With our unique provider of services we add value to
            businesses.
          </li>
        </ul>
        <Link className="see_all" to="/about-us">
          Read More <i className="fas fa-arrow-right"></i>
        </Link>
      </div>
    </div>
  </div>
)

export default LifeAtEGS
