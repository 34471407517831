import React from 'react'
import PageTitle from '../../common/PageTitle/PageTitle'
import PageImage from '../../common/PageImage/PageImage'
import { Helmet } from 'react-helmet'

const ProductStrategy = () => {
  return (
    <>
      <Helmet>
        <title>Product Strategy | Services | EGS</title>
        <meta name="description" content="Edge Global Services Product Strategy." />
        <meta name="keywords" content="" />
      </Helmet>
      <PageTitle h1="Product Strategy" />
      <div style={{textAlign: 'center'}}>
      <PageImage pageImage={`${process.env.REACT_APP_SITE_CONTENT}services/product-strategy.jpg`} altText="EGS - Product Strategy" />
      </div>
    </>
  )
}

export default ProductStrategy
