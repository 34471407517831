import React from 'react'
import PageTitle from '../../common/PageTitle/PageTitle'
import AboutUsSection from './AboutUsSection'
import { Helmet } from 'react-helmet'

const AboutUs = () => {
	return (
		<>
			<Helmet>
				<title>About | EGS</title>
				<meta name='description' content='EGS is a consultancy designed specifically around action and delivery.' />
				<meta name='keywords' content='' />
			</Helmet>
			<PageTitle
				h1='About Us'
				h4='Edge Global Services is your end to end solution for guaranteed results in Manufacturing, Sourcing, Quality
            Control we deliver on our promises.'
			/>
			<div className='container'>
				<div className='row py-5'>
					<div className='col-lg-3'></div>
					<div className='col-lg-6'>
						<div className='card'>
							<video
								controls
								className='card-img-top'
								name='media'
								poster={`${process.env.REACT_APP_SITE_CONTENT}infomercial-video-v2-poster.jpg`}
							>
								<source src={`${process.env.REACT_APP_SITE_CONTENT}infomercial-video-v2.mp4`} type='video/mp4' />
							</video>
							{/* <video controls className='card-img-top' name='media'>
								<source src={`${process.env.REACT_APP_SITE_CONTENT}why-egs.mp4`} type='video/mp4' />
							</video> */}
						</div>
					</div>
				</div>
				<AboutUsSection fontSize='19' />
				<div className='row py-5'>
					<div className='col-lg-12'>
						<div className='sec-title'>
							<h2>Why Edge Global Services</h2>
							<div className='separator'></div>
						</div>
						<div className='text' style={{ fontSize: 18 }}>
							With over 20 years of expertise across our specialist fields, EGS help your business gain the edge in
							today’s competitive and complex global markets. Whether you are starting a new venture or looking for the
							tools to propel an existing one forward, EGS will be the catalyst you need for sustainable, efficient
							growth. Bridging the knowledge gap between manufacturing, supply chain and product strategy disciplines,
							our number one priority is delivering tangible and impactful results to an ever-growing, international
							client base. Our core proposition is driven by the belief that analysis in consulting work is just a first
							step. Our focus thus lies on using the data gathered as a tool to deliver hard and fast results to your
							business.
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default AboutUs
