import React from 'react'
import { Helmet } from 'react-helmet'

import './Home.css'

import Carousel from './Carousel'
import OurServices from './OurServices'
import Appointment from './Appointment'
import HomeBackground from './HomeBackground'
import LifeAtEGS from './LifeAtEGS'
import RequestQuote from './RequestQuote'
import HomeBackgroundQuote from './HomeBackgroundQuote'
import AboutUsSection from '../About/AboutUsSection'

const Home = () => {
  const imageData = [
    {
      image: `${process.env.REACT_APP_SITE_CONTENT}slider4.jpg`,
      title: '',
      contactUsButton: false,
      readMoreButton: false,
      color: '',
    },
    {
      image: `${process.env.REACT_APP_SITE_CONTENT}slider3.jpg`,
      title: 'Global Manufacturing Base',
      contactUsButton: true,
      readMoreButton: false,
      color: '#FFFFFF',
    },
    {
      image: `${process.env.REACT_APP_SITE_CONTENT}slider5.jpg`,
      title: 'Manufacturing Sourcing & Fabrication',
      contactUsButton: true,
      readMoreButton: false,
      color: '#FFFFFF',
    },
    {
      image: `${process.env.REACT_APP_SITE_CONTENT}slider2.jpg`,
      title: 'Guaranteed Results',
      contactUsButton: true,
      readMoreButton: false,
      color: '#262261',
    },
    {
      image: `${process.env.REACT_APP_SITE_CONTENT}slider1.jpg`,
      title: 'We will deliver your business objectives and goals',
      contactUsButton: true,
      readMoreButton: false,
      color: '#262261',
    },
  ]

  return (
    <>
      <Helmet>
        <title>EGS | Home</title>
        <meta
          name="description"
          content="With over 20 years of expertise across our specialist fields, EGS help your business gain the edge in today’s competitive and complex global markets."
        />
        <meta name="keywords" content="" />
      </Helmet>
      <div style={{ height: 600, backgroundColor: 'transparent' }}>
        <Carousel imageData={imageData}/>
      </div>
      <HomeBackground>
        <div className="container">
          <div className="row pb-5">
            <div className="col-lg-4">
              <div className="narrow-card">
                <img
                  src={`${process.env.REACT_APP_SITE_CONTENT}manufacture.png`}
                  alt="EGS - Manufacture"
                  className="img-fluid"
                  style={{ marginTop: '-25px' }}
                />
                <div className="lower-content">
                  <h3>
                    <strong>Factory sourcing for product development &amp; Technical capability</strong>
                  </h3>
                  <div className="grey-line"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="narrow-card">
                <img
                  src={`${process.env.REACT_APP_SITE_CONTENT}procurement.png`}
                  alt="EGS - Procurement"
                  className="img-fluid"
                  style={{ marginTop: '-25px' }}
                />
                <div className="lower-content">
                  <h3>
                    <strong>Cost avoidance, Category management, Buying strategies</strong>
                  </h3>
                  <div className="grey-line"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="narrow-card">
                <img
                  src={`${process.env.REACT_APP_SITE_CONTENT}industry.png`}
                  alt="EGS - Industry"
                  className="img-fluid"
                  style={{ marginTop: '-25px' }}
                />
                <div className="lower-content">
                  <h3>
                    <strong>FMCG, DoD, Aerospace, IT and Consumer Electronics</strong>
                  </h3>
                  <div className="grey-line"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HomeBackground>
      <div style={{ backgroundColor: 'white', position: 'relative', width: '100%' }}>
        <div className="container">
          <OurServices />
          {/* <Infomercial /> */}
          <AboutUsSection titleText="About Us" subText="We deliver on our promises" fontSize="16" />
        </div>
      </div>

      <Appointment />
      <HomeBackground>
        <div className="container">
          <div className="row text-center">
            {[
              {
                urlName: 'Guaranteed Results',
                text: 'Business delivery under extreme presure',
                fa: 'far fa-clock fa-4x',
              },
              {
                urlName: 'Smart Solutions',
                text: 'Cold fixing situations in any supply chain situation',
                fa: 'fas fa-cog fa-4x',
              },
              {
                urlName: 'Easy and Affordable',
                text: 'Fixed contract no extras price include everything',
                fa: 'fas fa-pound-sign fa-4x',
              },
              {
                urlName: 'Team Of Professionals',
                text: 'With the team of experts we assure you guaranteed results',
                fa: 'fas fa-users fa-4x',
              },
            ].map((card, index) => (
              <div className="col-lg-3 statement-card" key={index}>
                <div className="py-4">
                  <i className={card.fa}></i>
                </div>
                <h3>{card.urlName}</h3>
                <div className="grey-line"></div>
                <div className="text pt-4">{card.text}</div>
              </div>
            ))}
          </div>
        </div>
      </HomeBackground>
      <LifeAtEGS />
      <HomeBackgroundQuote>
        <RequestQuote />
      </HomeBackgroundQuote>
    </>
  )
}

export default Home
