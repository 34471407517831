import React from 'react'

const NavBar = ({ links }) => (
  <nav
    className="navbar navbar-expand-md navbar-dark static-top bg-white p-2"
    style={{ marginTop: -20, zIndex: 1, marginBottom: 200 }}
  >
    <div className="container">
      <button
        className="navbar-toggler bg-primary"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
        style={{ visibility: 'hidden' }}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <button
        className="navbar-toggler bg-primary"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse" style={{ paddingBottom: 10 }}>
        <ul className="navbar-nav mx-auto">{links}</ul>
      </div>
    </div>
  </nav>
)

export default NavBar
