import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'

const Carousel = ({ imageData }) => {
  useEffect(() => {
    $(document).on('scroll', function () {
      var y = $(this).scrollTop()
      document.getElementById('myCarousel').style.top = `${90 - y / 2}px`
    })
    return () => {
      $(document).off('scroll')
    }
  }, [])

  return (
    <div
      id="myCarousel"
      className="carousel slide"
      data-ride="carousel"
      style={{ position: 'fixed', height: 690, width: '100%', top: '90px', zIndex: 0 }}
    >
      <ol className="carousel-indicators">
        {imageData.map((data, index) => (
          <li data-target="#myCarousel" key={index} data-slide-to={index} className="active"></li>
        ))}
      </ol>
      <div className="carousel-inner">
        {imageData.map((data, index) => (
          <div
            key={index}
            className={index === 0 ? 'carousel-item active' : 'carousel-item'}
            style={{
              backgroundImage: 'url(' + data.image + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              height: '690px',
              width: '100%',
            }}
          >
            <div className="container">
              <div className="carousel-caption" style={{ position: 'absolute', top: 200, color: data.color }}>
                <h1>{data.title}</h1>
                {data.contactUsButton ? (
                  <p className="mt-3">
                    <Link className="btn btn-lg btn-primary" to="/contact" role="button">
                      CONTACT US
                    </Link>
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        ))}
      </div>
      <a className="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
  )
}

export default Carousel
