import React from 'react'
import { Link } from 'react-router-dom'
import './IndustryCard.css'

const IndustryCard = ({ imageUrl, link, linkDescription, header, bulletPoints }) => {
	return (
		<div className='card h-100'>
			<div className='image' style={{ backgroundImage: 'url(' + imageUrl + ')', backgroundSize: 'cover' }}>
				{/* <img src={imageUrl} className='card-img-top' alt={linkDescription} /> */}
			</div>
			<div className='card-body'>
				<h3>
					<Link to={link}>{linkDescription}</Link>
				</h3>
				<div className='text'>
					<p style={{ height: 55 }}>
						<strong>{header}</strong>
					</p>
					{bulletPoints.map((point, index) => (
						<p key={index} style={{ fontSize: 16 }}>
							{index + 1}. {point}
						</p>
					))}
				</div>
			</div>
		</div>
	)
}

export default IndustryCard
