import React from 'react'

const AboutUsSection = ({ titleText, subText, fontSize }) => (
  <div className="row py-5">
    <div className="col-lg-8">
      {titleText ? (
        <>
          <div className="sec-title py-4">
            <h2>{titleText}</h2>
            <div className="separator"></div>
          </div>
        </>
      ) : null}
      {subText ? <div className="bold-text">{subText}</div> : null}
      <div className="text text-justify pr-4" style={{ fontSize: Number(fontSize) }}>
        EGS is a consultancy designed specifically around action and delivery. Our founder, Lou, spent many years
        engaging with solution-providers whose attention often fell on the presentation of intricate and sometimes
        unnecessary data analyses, rather than getting stuck in to delivering noticeable differences to businesses.
        Cutting his teeth in multiple FMCG roles before specialising in procurement and finally working for consultancy
        firms himself, Lou identified and understood the problem for businesses seeking consultants. This problem of
        hiring expectation versus the reality of theory-driven presentations, rather than a clear path of action to
        drive businesses forward is one he is determined to solve.
      </div>
    </div>
    <div className="col-lg-4">
      <div className="card shadow border-0">
        <div className="card-body" style={{ height: 400 }}>
          <div className="about-box">
            <i className="fas fa-bullseye icon" style={{marginTop: 40, marginLeft: 20}}></i>
            <h2 style={{marginLeft: 60, marginTop: 25, fontSize: 16, marginBottom: 25, color: '#262261', fontWeight: 'bold'}}>Our Number 1 Rule</h2>
            <hr />
          </div>
          <div className="text">
            Actions speak louder than words. We exist to help your business achieve its goals as quickly and efficiently
            as possible. Time is money, and as a transparent delivery consultancy we give you hard results, rather than
            jargon-filled analyses.
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default AboutUsSection
