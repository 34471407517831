import React from 'react'
import { Link } from 'react-router-dom'
import PageTitle from '../../common/PageTitle/PageTitle'
import { Helmet } from 'react-helmet'

const NotFound = () => (
  <>
    <Helmet>
      <title>Page Not Found | EGS</title>
    </Helmet>
    <PageTitle h1="Page Not Found" h4="The page you are looking for does not exist." />
    <div className="container">
      <div className="row">
        <div className="col text-center pt-2">
          <p className="read-more">
            <Link to="/">
              Click here to return to the Home Page <i className="fas fa-arrow-right"></i>
            </Link>
          </p>
        </div>
      </div>
    </div>
  </>
)

export default NotFound
